html {
  height: 100%;
}

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: Inter;
}
* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

* ::-webkit-scrollbar-track {
  -webkit-border-radius: 25px;
  border-radius: 25px;
}

/* Handle */
* ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: #00000010;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container {
  z-index: 2000000005;
}

.MuiMenu-root {
  /* margin-top: 44px !important; */
}
.MuiMenu-root .MuiList-root {
  max-width: 380px;
  min-width: 166px;
}

.MuiMenuItem-gutters img {
  margin-right: 10px;
}

.Mui-selected {
  color: #0496ff !important;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Black.ttf");
  font-weight: 900;
  font-display: block;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
  font-display: block;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
  font-display: block;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
  font-display: block;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
  font-display: block;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Light.ttf");
  font-weight: 300;
  font-display: block;
}
